export enum Step {
  Password = "password",
  PersonalInfo = "personal-info",
  CompanyInfo = "company-info",
  Subscription = "subscription",
  Final = "final",
  ProjectDetails = "project-details",
}

export enum InvitationType {
  UserInvitation = "UserInvitation",
  ClientOwnerInvitation = "ClientOwnerInvitation",
  ClientAdminInvitation = "ClientAdminInvitation",
  ConsultantInvitation = "ConsultantInvitation",
  SubscriptionUserInvitation = "SubscriptionUserInvitation",
}

export enum InvitationState {
  Accepted = "accepted",
  Pending = "pending",
}

export enum InvitationQueryKeys {
  InvitationType = "invitation_type",
  Token = "token",
}
